.humana_main {
    width: 1600px !important;
    margin: 0 auto;
    padding: 0px;
    margin-top: 48px;
    //    overflow-y: hidden;

    @media screen and (max-width: 1600px) {
        width: 100% !important;
        padding: 0;
    }
}

.scrollspy-example {
    width: 100%;
    padding: 16px;
    height: 100%;

    @media screen and (max-width: 1055px) {
        padding: 0px;
    }
}

.humana_main_banner {
    // height: vh;
    // background-image: url("../../../asset/humana.jpg");
    background-image: linear-gradient(to right, #FFFFFF 10%, transparent), url("../../asset/humana.jpg");
    background-size: cover;
    padding: 30px 40px;
    width: 100%;
    background-repeat: no-repeat;
    // height: 450px;
    background-position: center;

    @media screen and (max-width: 1050px) {
        background-image: none;
        height: auto;
        padding: 15px 15px;
    }

}

.humana_general_section {
    margin: 0px;
    margin-top: 335px;

    @media screen and (max-width: 1040px) {
        margin-top: 50px;
    }
}

.main_title {
    font-size: 3.6rem;
}

.humana_main_contant {
    width: 45%;
    display: flex;
    flex-direction: column;
    height: 100%;
    // gap: 60;
    justify-content: space-between;

    @media screen and (max-width: 1050px) {
        width: 100%;
        flex: none;
    }

}

.humana_contant_inner p {
    font-size: 22px;
    font-weight: 100px;
    margin-top: 280px;
    width: 500px;

    @media screen and (max-width: 1050px) {
        width: auto;
        margin-top: 15px;
    }
}

.mobile_img {
    display: none;

    @media screen and (max-width: 1050px) {
        display: block;
        margin-top: 20px;
        width: 100%;
        height: 400px;
    }

    @media screen and (max-width: 400px) {
        height: 280px !important;
    }
}

//Overview css

.overview-grid {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    gap: 20px
}

body {
    position: relative;
}


.humana_sidebar {
    @media screen and (max-width: 1055px) {
        display: none;
    }
}

.line {
    margin: 60px 0px;
    width: 100%;
    height: 1px;
    background-color: rgb(100, 100, 100);

    @media screen and (max-width: 1055px) {
        margin: 50px 0px;
    }
}

.bing_link {
    @media screen and (max-width: 1055px) {
        margin-top: 30px;
    }
}

.product_img {
    width: 100%;
}

.service_card {
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 320px) {
        height: 'auto';
    }
}

.service_card:hover {
    cursor: pointer;
}

.use_case_tab {

    @media screen and (max-width: 1055px) {
        display: none !important;
    }
}

.use_cases_accordion {
    display: none;

    @media screen and (max-width: 1055px) {
        margin-top: 30px;
        display: block;
    }
}

.case_study_img {
    // width: 50%;

    @media screen and (max-width: 1300px) {
        width: 100%;
    }
}

.case_study_img img {
    width: 100%;
    height: 500px;

    @media screen and (max-width: 1311px) {
        height: auto;
    }
}

.study_com {
    @media screen and (max-width: 1311px) {
        display: none;
    }
}

.study_mob {
    display: none;

    @media screen and (max-width: 1311px) {
        display: block;
    }
}

.case_study_contant {
    height: 100%;
    padding: 35px;
    background-color: #393939;
    color: #fff;
}

.card_border .card_main:first-child,
.card_border .card_main:nth-child(2),
.card_border .card_main:nth-child(4),
.card_border .card_main:nth-child(5) {
    border-right: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
}

.card_border .card_main:nth-child(3),
.card_border .card_main:nth-child(6) {
    border-bottom: 1px solid #e0e0e0;
}

.overview {
    @media screen and (max-width: 550px) {
        font-size: 32px !important;
    }
    
}

.what_we_do_our1 {
    font-size: 55px;
    line-height: 4rem;

    @media screen and (max-width: 671px) {
        font-size: 20px;
        line-height: 1.6rem;
        margin-top: 10px;
        font-weight: 400 !important;
    }
}

.get_start_btn {
    margin-top: 70px;
    margin-bottom: 70px;

    @media screen and (max-width: 550px) {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.get_start_btn button {
    @media screen and (max-width: 550px) {
        width: 100% !important;
    }
}

.get_reg {
    margin-left: 10px;

    @media screen and (max-width: 550px) {
        margin: 10px 0px 0px 0px;
    }

}

.hilite_mall_con_grid {
    @media screen and (max-width: 1600px) {
        padding: 0px;
    }

    @media screen and (max-width: 1055px) {
        padding: 0px 16px;
    }

    @media screen and (max-width: 671px) {
        padding: 0px;
    }
}