.possible_con {
  width: 1500px;
  margin: 0 auto;
  padding: 0;
  padding-top: 48px;

  @media screen and (max-width: 1600px) {
    width: 100%;
  }
}

.possible_banner {
  background-color: #262626;
  height: 480px;

  @media screen and (max-width: 500px) {
    height: 350px;
  }
}

.possible_login_ibm_btn {
  font-size: 1rem;
}

.possible_banner h1 {
  font-size: 60px;
  font-weight: 300;
  line-height: 1.199;
  color: #fff;

  @media screen and (max-width: 1500px) {
    font-size: 4vw;
  }

  @media screen and (max-width: 750px) {
    font-size: 7.5vw;
  }

  @media screen and (max-width: 500px) {
    font-size: 9vw;
  }
}

.inner_con {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 60%;

  @media screen and (max-width: 750px) {
    width: 100%;
  }
}

.possible_card {
  padding: 0;
  margin-top: 6rem !important;
  margin-bottom: 16rem !important;
}

.possible_card_title {
  width: 300px;
}

.possible_card_main {
  width: 100%;
}

.acoount_componet {
  width: 100%;
  padding: 0;
}

.produc-grid {}

.product_grid {
  @media screen and (max-width: 1055px) {
    margin-top: 30px;
  }
}

.product_grid2 {
  @media screen and (max-width: 1055px) {
    margin-top: 30px;
  }

  @media screen and (max-width: 671px) {
    margin-top: 0px;
  }
}

.possibal_card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 250px;
  border-right: 1px solid #cdcdcd;
}

.possibal_card p {
  font-size: 1.4rem;

}

.possibal_card span {
  font-size: 1rem;
}