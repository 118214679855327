.signup {
    margin-top: 48px;
    background-color: #ececec;

}

.signup_card {
    width: 768px;
    margin: 0 auto;
    background-color: #fff;
    padding: 24px 16px 32px;

    @media screen and (max-width: 850px) {
        width: 100%;
    }
}

.name_con {
    display: flex;
    width: 100%;
    justify-content: space-between;

    @media screen and (max-width: 1055px) {
        flex-direction: column;
    }
}

.user_name {
    width: 48%;
    margin-top: 30px;

    @media screen and (max-width: 1055px) {
        width: 100%;
    }
}
.service_card3 {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  
    @media screen and (max-width: 320px) {
      height: "auto";
    }
  }

.first-tile {
    margin-left: 0;
  }

.signup_card_headin {
    text-align: left;

    @media screen and (max-width: 550px) {
        text-align: left;
        font-size: 30px;
    }
}

.contact_address_cards {
    display: flex;
    justify-content: center;
    width: 768px;
    margin: 0 auto;
    margin-bottom: 20px;

    @media screen and (max-width: 850px) {
        width: 100%;
    }

    @media screen and (max-width: 667px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}

.contact_ind_card,
.contact_uae_card {
    width: 100%;

    @media screen and (max-width: 667px) {
        width: 100%;
    }
}

.contact_uae_card {
    margin-left: 20px;

    @media screen and (max-width: 667px) {
        margin-left: 0px;
        margin-top: 15px;
    }
}