.main_page {
    width: 1600px;
    margin: 0px auto;
    margin: 50px auto;

    @media screen and (max-width: 1600px) {
        width: 100%;
    }

}

.home_page_grid {
    padding: 40px 16px 32px 16px;

    @media screen and (max-width: 671px) {
        padding: 40px 0px 32px 0px;
    }
}

.main_banner_con {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.main_banner_con h1 {
    font-size: 4vw;

    @media screen and (max-width: 1055px) {
        font-size: 40px;
    }
}

.recommended_grid {
    @media screen and (max-width: 671px) {
        padding: 16px;
        ;
    }
}

.main_banner_con_tour {
    margin-bottom: 30px;

    @media screen and (max-width: 1055px) {
        margin-top: 20px;
    }
}

.main_banner_con_tour button {
    margin-top: 20px;
}

.main_page_img img {
    width: 100%;
    height: 40vw;

    @media screen and (max-width: 1300px) {
        height: 35vw;
    }

    @media screen and (max-width: 1055px) {
        height: 100%;
    }
}

.right_compont {
    border-bottom: 1px solid #e0e0e0;
}

.right_heder {
    font-size: 13px;
    padding: 10px 0px;
    color: #161616;
}

.recommended_card {
    background-color: #fff !important;
    border: 1px solid #e8e8e8;
    height: 200px;

    @media screen and (max-width: 1450px) {
        height: 200px;
    }

    @media screen and (max-width: 671px) {
        height: 200px;
    }

    @media screen and (max-width: 500px) {
        height: 180px;
    }
}

.browser_con_main_grid {
    padding: 60px 16px;

    @media screen and (max-width: 671px) {
        padding: 60px 0px;
    }
}

.recommended_card_img {
    background-color: #e8e8e8;
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;

    // @media screen and (max-width: 1300px) {
    //     height: 100%;
    // }

    @media screen and (max-width: 1055px) {
        height: 16vw;
    }

    @media screen and (max-width: 671px) {
        height: 25vw;
    }

    @media screen and (max-width: 500px) {
        height: 30vw;
    }

}

svg.my-custom-class {
    fill: #525252;
}

// .recommended_card_img img {
//     width: 100%;
//     height: 100%;
// }

.recommended_card:hover {
    background-color: #e8e8e8 !important;
    cursor: pointer;
}

.browser_title {
    font-size: 3.1vw;
    font-weight: 300;

    @media screen and (max-width: 1055px) {
        font-size: 40px;
    }

    @media screen and (max-width: 671px) {
        font-size: 35px;
    }

    @media screen and (max-width: 400px) {
        font-size: 25px;
    }
}

.browser_con {
    padding-left: 50px;

    @media screen and (max-width: 1055px) {
        padding-left: 0px;
    }
}

.browser_con p {
    font-size: 22px;
    font-weight: 400;

    @media screen and (max-width: 1055px) {
        margin-top: 20px;
    }
}

.course_card {
    border: 1px solid #e8e8e8;
    background-color: #fff !important;
    margin: 0px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 171px;
    transition: transform 0.5s ease-in-out;
    cursor: pointer;

    @media screen and (max-width: 671px) {
        height: 250px;
    }
}

.title_2 {
    color: #161616;
    font-size: 16px;
    font-weight: 400;
    transform: translateY(-50%);
    transition: transform 0.2s ease-in-out;
    opacity: 0;

    @media screen and (max-width: 671px) {
        display: none;
    }
}

.title_1 {
    color: #161616;
    font-size: 16px;
    font-weight: 400;
    display: block;
    width: 100%;

    @media screen and (max-width: 671px) {
        display: none;
    }
}

.title_1_mobile {
    display: none;
    color: #161616;
    font-size: 16px;
    font-weight: 400;
    width: 100%;

    @media screen and (max-width: 671px) {
        display: block;
    }
}

.course_card:hover .title_1 {
    display: none;
    opacity: 0;
}

.course_card:hover .title_2 {
    // display: block;
    opacity: 1;
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
}

.title_2_mobile {
    display: none;

    @media screen and (max-width: 671px) {
        display: block;
        margin-top: 15px;
    }
}

.browser_card_con {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.course_card:hover {
    background-color: #e8e8e8 !important;
}

.power_ai_con {
    background-color: #f4f4f4;
    padding: 30px;

    @media screen and (max-width: 671px) {
        padding: 20px;
    }
}

.power_heading {
    font-size: 3vw;
    margin-top: 20px;
    font-weight: 300;

    @media screen and (max-width: 671px) {
        font-size: 23px;
    }
}

.power_heading_con {
    margin-top: 20px;
    font-size: 16px;
}


.what_new_img {
    position: relative;
}

.image-cover {
    width: 100%;
    height: auto;
    display: none; /* Hide both images by default */
}

/* Web view */
@media screen and (min-width: 768px) {
    .web-image {
        display: block; /* Display web image for screens larger than 768px */
    }
}

/* Mobile view */
@media screen and (max-width: 767px) {
    .mobile-image {
        display: block; /* Display mobile image for screens smaller than 768px */
    }
}

.what_new_img img {
    width: 100%;
    height: 100%;
    // object-fit: cover;
}

.what_read_report {
    position: absolute;
    right: 20px;
    background-color: #393939;
    width: 350px;
    height: 175px;
    bottom: 0px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    @media screen and (max-width: 617px) {
        width: 50%;
        height: 40%;
        bottom: 0px;
        right: 0px;
    }
}

.what_read_report p {
    color: #fff;
    font-weight: 600;
}

.meet_our {
    font-size: 3.1vw;
    font-weight: 300;
    padding-right: 20px;

    @media screen and (max-width: 1055px) {
        font-size: 40px;
    }

    @media screen and (max-width: 671px) {
        font-size: 33px;
    }
}

.browser_card_main {
    padding: 60px 40px 96px 40px;

    @media screen and (max-width: 1055px) {
        padding: 60px 32px 96px 32px;
    }

    @media screen and (max-width: 671px) {
        padding: 60px 16px 96px 16px;
    }
}

.meet_our_con {
    font-size: 22px;

    @media screen and (max-width: 1055px) {
        margin-top: 25px;
    }
}

.research_des {
    margin-top: 10px;
    font-size: 23px;
    height: 150px;
    color: #161616;
}

.home_card img {
    width: 100%;
}

.home_card {
    margin: 0px;
    padding: 0px;
}

.artifical_card .artifical_card_inside:first-child,
.artifical_card .artifical_card_inside:nth-child(2),
.artifical_card .artifical_card_inside:nth-child(3) {
    border-right: 1px solid #e0e0e0;
}