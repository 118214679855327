@use '@carbon/react';

@media (min-width: 42rem) {
  .cds--grid--no-gutter {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.cds--content {
  // margin-top: 3rem;
  background: var(--cds-background);
}