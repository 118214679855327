.footer_main {
  background-color: #262626;
  color: #c6c6c6;
  // display: flex;
  padding: 90px 70px;

  @media screen and (max-width: 1600px) {
    padding: 18px 32px 100px;
  }

  @media screen and (max-width: 1055px) {
    padding: 40px 20px 115px 20px;
  }

  @media screen and (max-width: 671px) {
    padding: 30px 0px 115px 0px;
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;
  }
}

.footer_logo img {
  width: 60%;

  @media screen and (max-width: 1050px) {
    width: 30%;
  }

  @media screen and (max-width: 671px) {
    width: 40%;
  }

  @media screen and (max-width: 550px) {
    width: 50%;
    margin-bottom: 0px;
  }
}

.footer_con_main {
  width: 80%;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;

  @media screen and (max-width: 550px) {
    padding: 0;
    width: 100%;
    margin-top: 30px;
  }
}

.con_section {
  text-align: left;
  margin: 10px 25px;
  // background-color: green;
}

.footer_con_inner {}

.footer_con_inner p {
  color: #f4f4f4;
  font-size: 14px !important;
  margin-top: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.heder_navigation .cds--header__menu li {
  border-bottom: 1px solid #e0e0e0;
}

.cds--side-nav--ux .cds--side-nav__items {
  padding-top: 0;
}

.cds--side-nav a.cds--header__menu-item {
  border-bottom: 1px solid #e0e0e0;
  margin: 4px;
  padding: 12px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #525252;
}

.HeaderSupport {
  z-index: 999999;
  width: 100%;
  padding: 16px 40px;
  height: auto;
  background-color: #ffffff;
  position: fixed;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 6px 20px rgba(0, 0, 0, 0.1);
  top: 48px;
  color: #525252;
}

.HeaderSupport h4 {
  gap: 2px;
  padding: 4px;
}

.HeaderSupport h4:hover {
  cursor: pointer;
  color:#0062fe;
}

.HeaderSupportMobile {
  z-index: 999999;
  width: 255px;
  top: 48px;
  // padding: 16px;
  height: 100%;
  background-color: #ffffff;
  position: fixed;
}

.HeaderSupportMobile .mobileMenu {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e0e0e0;
  margin: 5px;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.375;
  padding: 12px;
}

.HeaderSupportMobile .mobileMenu h4 {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375;
  color: #525252;
}

.HeaderSupportMobile .mobileMenu:hover {
  cursor: pointer;
  text-decoration: underline;
}

.backButton {
  margin: 0 !important;
  justify-content: flex-start !important;
  color: #0062fe;
  border: 1px solid #0062fe !important;
}

.titleHeader {
  margin: 0 !important;
  color: black;
  border: 1px solid black !important;
  border-top: none !important;
}

// .footer_content {
//   padding: 10px;
// }

.footer_content_heder {
  font-weight: 600;
}

.footer_content_heder {
  font-weight: 600;
}

.product_title {
  font-weight: 700;
  font-size: 17px;
}

.contact_footer {
  font-weight: 700;
  font-size: 17px;
  cursor: pointer;
}

.about_carousel .carousel-control-next,
.about_carousel .carousel-control-prev {
  position: unset;
}

.about_carousel .carousel-control-prev span {
  position: absolute;
  bottom: -65px;
  right: 8rem;
  height: 42px;
  width: 42px;
  background-size: cover;
  // padding: 16px;
  transform: rotate(180deg);
  background-image: url(../asset/right-arrow-svgrepo-com.svg) !important;
  // background-color: black;
}

.about_carousel .carousel-control-next span {
  position: absolute;
  bottom: -65px;
  right: 0rem;
  height: 42px;
  width: 42px;
  background-size: cover;
  // padding: 16px;
  // transform: rotate(180deg);
  background-image: url(../asset/right-arrow-svgrepo-com.svg) !important;
  // background-color: black;
}

.about_carousel .item_number {
  position: absolute;
  bottom: -53px;
  font-size: 20px;
  font-weight: 600;
  right: 4rem;
}

.about_carousel .carousel-item {
  position: relative;
}

.about_carousel .text_box {
  padding: 16px;
  background-color: #f2f4f8;
  bottom: 0;
  right: 0;
  width: 400px;
  position: absolute;
  z-index: 999;

  @media screen and (max-width: 500px) {
    display: none;
  }
}

// .header_div{
//   position: relative;
// }
.header_main {
  // padding: 0px 4rem;

  @media screen and (max-width: 1600px) {
    padding: 0px 16px;
  }

  @media screen and (max-width: 1055px) {
    padding: 16px 30px 16px 0px;
  }
}

.footer_main_grid {
  // background-color: red;
  // justify-content: flex-end;
  // align-items: flex-end;
  margin: 25px 77px 53px;
  // width: 100%;

  @media screen and (max-width: 1055px) {
    padding: 0px;
  }

  @media screen and (max-width: 671px) {
    display: none;
  }
}

.footer_grid_main {
  @media screen and (max-width: 1055px) {
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .hidden-sm {
    display: none !important;
  }
}


.footer_con_grid {
  border-top: 1px solid #6f6f6f;
  border-bottom: 1px solid #6f6f6f;

  @media screen and (max-width: 1055px) {
    padding: 0px;
  }

  @media screen and (max-width: 671px) {
    border-top: 0px;
  }
}

.footer_insight_grid {
  padding-bottom: 15px;
  border-bottom: 1px solid #6f6f6f;
  
}


.footer_accordion {
  display: none;
  width: 100%;

  @media screen and (max-width: 671px) {
    display: block;
  }
}

.footer_contact {
  color: #ffffff;
  font-size: 14px;
  margin: 20px 60px 53px;
  cursor: pointer;

  @media screen and (max-width: 671px) {
    margin: 20px 0px 53px;
  }
}

.footer_accordion {
  color: #fff;
}

.footer_accordion .cds--accordion__title {
  color: white;
}

.footer_accordion .cds--accordion__heading:focus {
  border: 2px solid #fff !important;
}

.footer_accordion .cds--accordion__heading:hover {
  background-color: #4c4c4c;
}

.footer_accordion .cds--accordion__arrow {
  fill: #fff;
}

.cds--accordion__item {
  border-block-start: 1px solid #6f6f6f !important;
}

.cds--accordion__item:last-child {
  border-block-end: 1px solid #6f6f6f !important;

}


.list-group-item.active {
  background-color: white !important;
  border: none;
  border-left: 5px solid #0f62fe !important;
  color: black !important;
}

.list-group-item {
  border: none !important;
  padding: 16px 16px !important;
  border-left: 5px solid #e0e0e0 !important;
  border-radius: 0 !important;
}

.list-group-item:hover {
  border: 2px solid #0f62fe !important;
  border-left: 5px solid #0f62fe !important;
}

.main_header_div {
  width: 1600px;
  margin: 0 auto;
  padding: 0px 16px;
  display: flex;
  @media screen and (max-width: 1600px) {
    width: 100%;
    padding: 0px;
  }
}