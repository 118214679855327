.get_ready_cases {
    width: 1600px;
    padding-top: 58px;
    margin: 0 auto;

    @media screen and (max-width: 1600px) {
        width: 100%;
    }
}

.main_grid {
    margin-top: 44px;
    padding: 0 !important;
}

.first_grid {
    margin: 0px !important;
}

.second_grid {
    border-left: 2px solid #eeeeee;
    padding: 24px;

    @media screen and (max-width: 1055px) {
        border-left: none;
    }
}

.first_a_grid {
    @media screen and (max-width: 670px) {
        display: flex;
    }
}

.first_b_grid p {
    font-size: 1rem;
    line-height: 1.625rem;
    padding: 0 0 15px;
    color: #323232;
    font-weight: 400;
}

.first_b_grid p a:hover {
    text-decoration: underline !important;
}

.first_b_grid ul {
    padding-left: 40px;
}

.first_b_grid ul li {
    list-style-type: disc;
}

.first_b_grid ul {
    padding-left: 60px;

    @media screen and (max-width: 1050px) {
        padding-left: 0px;
    }
}

.btn_card {
    display: flex;
}

.getredy_prfole{
    border-bottom: 1px solid gray;
    margin-bottom: 40px;
}