.artificial_main {
  width: 1600px !important;
  margin: 0 auto;
  padding: 0px;

  @media screen and (max-width: 1600px) {
    width: 100% !important;
    padding: 0;
  }
}

.artificial_main_banner {
  background-image: url("../../asset/artificial-intelligence-banner.jpeg");
  background-size: cover;
  padding: 30px 40px;
  width: 100%;
  margin-top: 48px;
  background-repeat: no-repeat;

  @media screen and (max-width: 1050px) {
    background-image: none;
    height: auto;
    padding: 16px 16px;
  }
}

.main_title {
  font-size: 64px !important;
  color: #161616;

  @media screen and (max-width: 1600px) {
    font-size: 3.5vw !important;
  }

  @media screen and (max-width: 1055px) {
    font-size: 35px !important;
  }

  @media screen and (max-width: 617px) {
    font-size: 35px !important;
    // padding: 0px 16px;
  }
}

.byjAI {
  @media screen and (max-width: 540px) {
    font-size: 28px !important;
  }
}

.blockheading {
  // color: #e0e0e0;
  font-size: 3.2vw;
  font-weight: 300;
  line-height: 1.25;
  margin-bottom: 2rem;

  @media screen and (max-width: 1055px) {
    font-size: 40px;
  }

  @media screen and (max-width: 671px) {
    font-weight: 400;
  }
}

.overView_con {
  padding-right: 70px;

  @media screen and (max-width: 1055px) {
    padding-right: 0px;
  }
}

.benifits {
  font-size: 66px;
  color: #161616;
  font-weight: 300;

  @media screen and (max-width: 1600px) {
    font-size: 3.5vw !important;
  }

  @media screen and (max-width: 1055px) {
    font-size: 40px !important;
  }
}

.benefit_line {
  height: 1px;
  background-color: #e0e0e0;
  width: 100%;
  margin-top: 96px;

  @media screen and (max-width: 617px) {
    margin-top: 40px;
  }
}

.services_line {
  height: 1px;
  background-color: rgb(75, 73, 73);
  width: 100%;
  margin-top: 70px;
}

.artificial_main_contant {
  width: 45%;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @media screen and (max-width: 1050px) {
    width: 100%;
    flex: none;
  }
}

.artificial_contant_inner p {
  font-size: 22px;
  font-weight: 400;
  margin-top: 130px;
  color: #161616;

  @media screen and (max-width: 1050px) {
    margin-top: 45px;
  }

  @media screen and (max-width: 540px) {
    font-size: 20px !important;
  }
}

.artificial_contant_btn {
  margin-top: 20px;

  // font-family: Arial, Helvetica, sans-serif;
  // font-size: 16px !important;
  display: flex;

  @media screen and (max-width: 415px) {
    flex-direction: column;
  }
}

.btn_meeting {
  font-size: 16px;
}

.exploer_btn1 {
  margin-left: 0px;

  @media screen and (max-width: 415px) {
    margin: 0px;
    margin-top: 15px;
  }
}
.exploer_btn2 {
  margin-left: 15px;

  @media screen and (max-width: 415px) {
    margin: 0px;
    margin-top: 15px;
  }
}

.mobile_img {
  display: none;

  // object-fit: contain;
  @media screen and (max-width: 1050px) {
    display: block;
    margin-top: 20px;
    width: 100%;
    height: 400px;
  }

  @media screen and (max-width: 400px) {
    height: 280px !important;
  }
}

.mobile_img2 {
  display: none;

  // object-fit: contain;
  @media screen and (max-width: 1050px) {
    display: block;
    margin-top: 20px;
    width: 100%;
    height: 10;
  }

  @media screen and (max-width: 400px) {
    height: 280px !important;
  }
}

//Overview css

.overview-grid {
  display: flex;
  // justify-content: space-between;
  flex-direction: column;
  gap: 20px;
}

body {
  position: relative;
}

.artificial_sidebar {
  @media screen and (max-width: 1055px) {
    display: none;
  }
}

.line {
  margin: 70px 0px;
  width: 100%;
  height: 2px;
  background-color: #e0e0e0;

  @media screen and (max-width: 1055px) {
    margin: 64px 0px;
  }
}

.bing_link {
  @media screen and (max-width: 1055px) {
    margin-top: 30px;
  }
}

.benifit_column {
  border-left: 1px solid #e0e0e0;

  @media screen and (max-width: 1055px) {
    border: none;
  }
}

.benifit_link {
  margin-left: 20px;

  @media screen and (max-width: 1055px) {
    margin-left: 0px;
  }
}

.product_img {
  width: 100%;
}

.service_cards {
  height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 320px) {
    height: "auto";
  }
}

.service_card1 {
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 320px) {
    height: "auto";
  }
}
.service_card2 {
  height: 270px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 320px) {
    height: "auto";
  }
}

.use_case_tab {
  padding-bottom: 30px;

  @media screen and (max-width: 1055px) {
    display: none !important;
  }
}

.use_cases_accordion {
  display: none;

  @media screen and (max-width: 1055px) {
    margin-top: 30px;
    display: block;
  }
}

.case_study_img {
  // width: 50%;

  @media screen and (max-width: 1300px) {
    width: 100%;
  }
}

.case_study_img img {
  width: 100%;
  height: 500px;

  @media screen and (max-width: 1311px) {
    height: auto;
  }
}

.study_com {
  @media screen and (max-width: 1311px) {
    display: none;
  }
}

.study_mob {
  display: none;

  @media screen and (max-width: 1311px) {
    display: block;
  }
}

.case_study_contant {
  height: 100%;
  padding: 35px;
  background-color: #393939;
  color: #fff;
}

.card_border .card_main:first-child,
.card_border .card_main:nth-child(2),
.card_border .card_main:nth-child(4),
.card_border .card_main:nth-child(5),
.card_border .card_main:nth-child(7),
.card_border .card_main:nth-child(8),
.card_border .card_main:nth-child(10)
 {
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
}

.card_border .card_main:nth-child(3),
.card_border .card_main:nth-child(6),
.card_border .card_main:nth-child(9) {
  border-bottom: 1px solid #e0e0e0;
}

.get_start {
  background-color: #f4f4f4;
  // margin: 0;
  margin-top: 70px;
  padding: 50px 50px 90px 50px;

  @media screen and (max-width: 550px) {
    padding: 15px;
  }
}

.get_start_new_btn {
  margin-top: 30px;

  @media screen and (max-width: 550px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.get_start_new_btn button {
  @media screen and (max-width: 550px) {
    width: 100% !important;
  }
}

.get_reg {
  margin-left: 10px;

  @media screen and (max-width: 550px) {
    margin: 10px 0px 0px 0px;
  }
}

.use_cases_tab_main {
  display: flex;
  width: 100%;

  @media screen and (max-width: 1055px) {
    flex-direction: column;
  }
}

.tab_img img {
  width: 100%;
  height: 100%;
  margin-top: 20px;
}

.product_con {
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: 671px) {
    height: 150px;
  }
}

.use_cases_tab_con {
  width: 55%;

  @media screen and (max-width: 1055px) {
    width: 100%;
  }
}

.emporia_scroll {
  width: 100%;
  padding: 16px;
  margin-left: 0px;
  height: 100%;
  background-color: red;
}

.product_img_card {
  position: absolute;
  bottom: 0;
  width: 32%;
  right: 0;
  height: 291px;
  @media screen and (max-width: 1055px) {
    width: 49%;
  }

  @media screen and (max-width: 671px) {
    display: none;
  }
}

.product_img_card_mobile {
  display: none;

  @media screen and (max-width: 671px) {
    display: block;
  }
}

.featured_card1 {
  padding: 0;
  height: 500px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 320px) {
    height: 540px;
  }
  @media screen and (max-width: 500px) {
    height: 540px;
  }
}

.featured_card_img1 img {
  width: 100%;
  height: 200px;

  @media screen and (max-width: 671px) {
      height: 400px;
  }

  @media screen and (max-width: 500px) {
      height: 250px;
  }

}

.featured_card_con1 {
  padding: 0px 15px 5px 15px;
}
