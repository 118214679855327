.case_study_main {
    margin-top: 48px;
}

.featured_card {
    padding: 0;
}

.featured_card_img img {
    width: 100%;
    height: 250px;

    @media screen and (max-width: 671px) {
        height: 400px;
    }

    @media screen and (max-width: 500px) {
        height: 250px;
    }

}

.featured_card_con {
    padding: 0px 15px 5px 15px;
}

.pre_tools_section {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 750px) {
        align-items: center;
        flex-direction: column;
    }
}

.icon_img {
    text-align: right;
}

.icon_img img {
    border: 1px solid #0f62fe;
    padding: 12px;
    margin: 12px;
}

.insights_main_con {
    margin-top: 100px;

    @media screen and (max-width: 1055px) {
        margin-top: 10px;
    }
}