.bx--accordion__heading {
    font-size: 26px;
    background-color: red;
    /* Adjust the font size as needed */
}

.bio_img {
    @media screen and (max-width: 671px) {
        text-align: center;
    }
}

.bio_img img {
    width: 90%;

    @media screen and (max-width: 1055px) {
        width: 100%;
        // height: 400px;
    }

    @media screen and (max-width: 671px) {
        width: 70%;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.profile_main {
    margin-top: 90px;
}

.accordion_main .cds--accordion__content {
    padding: 0px;
    padding-bottom: 10px;
    background-color: #f2f4f8;
}

.accordion_column {
    margin-top: 15px;
    padding: 20px;
}

.bio_accordion_con {
    background-color: #fff;
    font-size: 20px;
    cursor: pointer;
    height: 100%;
    padding: 20px;
    width: 98%;
}