.about_main {
    width: 1600px !important;
    margin: 0 auto;
    padding: 0px;
    // overflow-x: hidden;

    @media screen and (max-width: 1600px) {
        width: 100% !important;
        padding: 0;
    }
}

.main_title {
    font-size: 3rem;
}

.about_contant_btn {
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;

    @media screen and (max-width: 560px) {
        flex-direction: column;
    }
}

.about_image {
    width: 100%;
    background-repeat: no-repeat;

    @media screen and (max-width: 1050px) {
        height: auto;
        // padding: 15px 15px;
    }
}

.what_we_do_banner_des {
    font-size: calc(0.04412rem + 1.47059vw);

    @media screen and (max-width: 1055px) {
        margin-top: 20px;
        font-size: 20px;
    }
}

.btn_meeting {
    font-size: 16px;
}

.what_we_do_exploer_btn {
    margin-left: 15px;

    @media screen and (max-width: 562px) {
        margin-left: 0px;
        margin-top: 15px;
    }

    @media screen and (max-width: 415px) {
        margin: 0px;
        margin-top: 15px;
    }
}


.mobile_img {
    display: none;

    @media screen and (max-width: 1050px) {
        display: block;
        margin-top: 20px;
        width: 100%;
        height: 400px;
    }

}

//Overview css

.overview-grid {
    display: flex;
    // justify-content: space-between;
    flex-direction: column;
    gap: 20px
}

body {
    position: relative;
}

.artificial_sidebar {
    @media screen and (max-width: 1055px) {
        display: none;
    }
}


.tab_about img {
    width: 95%;
    height: fit-content;

    @media screen and (max-width: 1055px) {
        // display: none;
        width: 100%;
    }
}

.tab_about_box {
    margin-top: 10px;
    width: 30%;

    @media screen and (max-width: 1055px) {
        width: 100%;
    }
}

.about_overview_tab_common {
    display: flex;
    margin-top: 20px;
}

.about_tablist {
    font-size: 15px;
}

.line {
    margin: 50px 0px;
    width: 100%;
    height: 0.5px;
    background-color: #707070;

    @media screen and (max-width: 1055px) {
        margin: 50px 0px;
    }
}

.bing_link {
    @media screen and (max-width: 1055px) {
        margin-top: 30px;
    }
}



.use_case_tab .cds--tab-content {
    padding: 0px;
    padding-top: 20px;
}

.use_case_tab {

    @media screen and (max-width: 1055px) {
        display: none !important;
    }
}

.use_cases_accordion {
    display: none;

    @media screen and (max-width: 1055px) {
        margin-top: 30px;
        display: block;
    }
}

.case_study_img {
    // width: 50%;

    @media screen and (max-width: 1300px) {
        width: 100%;
    }
}

.case_study_img img {
    width: 100%;
    height: 500px;

    @media screen and (max-width: 1311px) {
        height: auto;
    }
}


.artificial_sidebar1 {
    display: none;
}


@media screen and (max-width: 1055px) {
    .artificial_sidebar1 {
        z-index: 999;
        display: block;
    }
}

.next_Steps {
    background-color: #f4f4f4;
    // margin: 0;
    margin-top: 70px;
    padding: 50px 50px 200px 50px;

    @media screen and (max-width: 550px) {
        padding: 15px;
    }
}

.what_we_do_our {
    font-size: 55px;
    line-height: 4rem;

    @media screen and (max-width: 671px) {
        font-size: 20px;
        line-height: 1.6rem;
        margin-top: 10px;
        font-weight: 400 !important;
    }
}

.our_impact_card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}

.what_we_do_accordion .cds--accordion__item {
    border-block-start: 1px solid #e0e0e0 !important;
}

.what_we_do_accordion .cds--accordion__item:last-child {
    border-block-end: 1px solid #e0e0e0 !important;
}

// .cds--accordion__item:last-child

.what_we_mobile_accordion .cds--accordion__content {
    @media screen and (max-width: 1055px) {
      padding: 10px 15px !important;
    }
  }