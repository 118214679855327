.get_ready_cases {
    width: 1600px;
    margin: 100px auto;

    @media screen and (max-width: 1600px) {
        width: 100%;
    }
}

.main_box:hover {
    cursor: pointer;
}

.main_box:hover .box_content h4,
.main_box:hover .box_content p {
    text-decoration: underline;
}

.box_content {
    background-color: #fff;
    height: 120px;
    padding: 10px 16px;
}

.executive_data {
    width: 1600px;
    margin: 0 auto;

    @media screen and (max-width: 1600px) {
        width: 100%;
    }
}